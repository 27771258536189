import React from 'react'

import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'

const NotFoundPage = () => (
  <Layout>
    <Seo title="Не найдено" />
    <h1>Не найдено</h1>
  </Layout>
)

export default NotFoundPage
